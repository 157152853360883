import React, { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

export function Home(props) {

const gotoPage = (ta) => {
	props.setPage(ta);
}
const onClick = (ta) => {
  console.log("click: "+ta);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event_click',
    clickBtn: ta
  });
}

useEffect(() => {
  console.log("page: home");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event_page',
    page: "home"
  });
}, []);

return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Page: Home
      </p>
      <button id="linkBtn_home" className="linkBtn" onClick={() => gotoPage("cnt01")}>Content 01</button>
      <button id="linkBtn_cnt02" className="linkBtn" onClick={() => gotoPage("cnt02")}>Content 02</button>
      <button id="clickBtn01" className="clickBtn" onClick={() => onClick("btn01")}>Clik Btn 01</button>
    </header>
);
}
