import React, { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

export function Cnt02(props) {

const gotoPage = (ta) => {
	props.setPage(ta);
}
const onClick = (ta) => {
  console.log("click: "+ta);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event_click',
    clickBtn: ta
  });
}

useEffect(() => {
  console.log("page: cnt02");

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'event_page',
    page: "cnt02"
  });
}, []);

return (
    <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Page: Contents 02
      </p>
      <button id="linkBtn_home" className="linkBtn" onClick={() => gotoPage("home")}>Home</button>
      <button id="linkBtn_cnt01" className="linkBtn" onClick={() => gotoPage("cnt01")}>Content 01</button>
      <button id="clickBtn03" className="clickBtn" onClick={() => onClick("btn03")}>Clik Btn 03</button>
    </header>
);
}
