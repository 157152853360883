import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import {Home} from "./Home";
import {Cnt01} from "./Cnt01";
import {Cnt02} from "./Cnt02";

function App() {

const [page, setPage] = useState("home");

return (
  <div className="App">
    {
      (() => {
        if (page == "home") {
          return(
            <Home page={page} setPage={setPage}></Home>
          )
        } else if (page == "cnt01") {
          return (
            <Cnt01 page={page} setPage={setPage}></Cnt01>
          )
        } else if (page == "cnt02") {
          return (
            <Cnt02 page={page} setPage={setPage}></Cnt02>
          )
        }
      })()
    }
  </div>
);

}

export default App;
